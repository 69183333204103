
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useI18n } from "vue-i18n";
import moment from "moment";
import { useRoute } from "vue-router";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";

interface ITeacher {
  _id: string;
  employee: {
    firstName: string;
    lastName: string;
    photo: string;
    birthDate: string;
  };
}

interface Subject {
  _id: string;
  name: string;
}

export default defineComponent({
  name: "profile-overview",
  components: {},
  setup() {
    const { t } = useI18n();

    const route = useRoute();
    const teacherID = route.params.id;
    const trimester = JwtService.getTrimester();
    const totalHours = ref(0);
    const today = ref(0);
    const tomorrow = ref(0);
    const loading = ref(true);

    const teacher = ref<ITeacher>({
      _id: "",
      employee: {
        firstName: "",
        lastName: "",
        photo: "",
        birthDate: "",
      },
    });

    const levels = ref<string[]>([]);
    const subjects = ref<Subject[]>([]);

    onMounted(async () => {
      setCurrentPageBreadcrumbs(t("teacher.teacherProfile"), []);

      await ApiService.post("/teachers/filter", {
        query: { _id: teacherID },
      })
        .then(({ data }) => {
          if (data.length > 0) teacher.value = data[0];
          else console.error(data);
        })
        .catch((e) => console.log(e));

      ApiService.post("/schedules/filter", {
        query: {
          teacher: teacherID,
          status: { $ne: "inactive" },
          hidden: { $ne: true },
        },
        aggregation: [
          {
            $lookup: {
              from: "teachers",
              localField: "teacher",
              foreignField: "_id",
              as: "teacher",
            },
          },
          {
            $lookup: {
              from: "subjects",
              localField: "subject",
              foreignField: "_id",
              as: "subject",
            },
          },
          {
            $lookup: {
              from: "modules",
              localField: "module",
              foreignField: "_id",
              as: "module",
            },
          },
          {
            $lookup: {
              from: "modulespecifics",
              localField: "moduleSpecific",
              foreignField: "_id",
              as: "moduleSpecific",
            },
          },
          {
            $lookup: {
              from: "rooms",
              localField: "room",
              foreignField: "_id",
              as: "room",
            },
          },
          {
            $lookup: {
              from: "classrooms",
              localField: "classeRoom",
              foreignField: "_id",
              as: "classeRoom",
            },
          },
          {
            $project: {
              _id: 1,
              teacher: {
                $arrayElemAt: ["$teacher.employee", 0],
              },
              classeRoom: {
                $let: {
                  vars: {
                    classVar: {
                      $arrayElemAt: ["$classeRoom", 0],
                    },
                  },
                  in: {
                    name: "$$classVar.name",
                    level: "$$classVar.level",
                    _id: "$$classVar._id",
                  },
                },
              },
              time: 1,
              day: 1,
              room: {
                $let: {
                  vars: {
                    roomVar: {
                      $arrayElemAt: ["$room", 0],
                    },
                  },
                  in: {
                    name: "$$roomVar.name",
                    _id: "$$roomVar._id",
                  },
                },
              },
              subject: {
                $let: {
                  vars: {
                    subjectVar: {
                      $arrayElemAt: ["$subject", 0],
                    },
                  },
                  in: {
                    name: "$$subjectVar.name",
                    _id: "$$subjectVar._id",
                    status: "$$subjectVar.status",
                  },
                },
              },
              module: {
                $let: {
                  vars: {
                    moduleVar: {
                      $arrayElemAt: ["$module", 0],
                    },
                  },
                  in: {
                    name: "$$moduleVar.name",
                    _id: "$$moduleVar._id",
                    status: "$$moduleVar.status",
                  },
                },
              },
              moduleSpecific: {
                $let: {
                  vars: {
                    moduleSpecificVar: {
                      $arrayElemAt: ["$moduleSpecific", 0],
                    },
                  },
                  in: {
                    name: "$$moduleSpecificVar.name",
                    _id: "$$moduleSpecificVar._id",
                    status: "$$moduleSpecificVar.status",
                    subjects: "$$moduleSpecificVar.subjects",
                  },
                },
              },
              ends: 1,
              duration: 1,
              type: 1,
            },
          },
        ],
      })
        .then(({ data }) => {
          data = data
            .map((d) => {
              if (!d.type) d.type = "S";
              if (d.type == "MP") d.subject = d.module;
              if (d.type == "MS") d.subject = d.moduleSpecific;
              return d;
            })
            .filter((d) => d.subject.status !== "inactive");
          const day = new Date().getDay() - 1;
          data.forEach((l) => {
            const hours = Number(l.ends.split(":")[0] - l.time.split(":")[0]);
            totalHours.value += hours;
            if (day == l.day) today.value += hours;
            if ((day + 1) % 6 == l.day) tomorrow.value += hours;

            if (!levels.value.includes(l.classeRoom.level))
              levels.value.push(l.classeRoom.level);
            if (!subjects.value.find((s) => s._id == l.subject._id))
              subjects.value.push(l.subject);
          });
        })
        .catch((e) => console.log(e))
        .finally(() => {
          loading.value = false;
        });
    });

    return {
      t,
      teacher,
      moment,
      today,
      tomorrow,
      loading,
      totalHours,
      levels,
      subjects,
    };
  },
});
